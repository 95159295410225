<template>
  <div class="menu-mobile">
    <ul>
      <router-link v-slot="{ isActive }" class="menu" :to="{ name: 'Home' }">
        <li :class="[isActive && 'menu active']">
          <span>
            <svg
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 23 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#a)" fill="#283646">
                <path
                  d="m22.08 19.787l-8.7181-8.7182-10.186-10.186h-0.79601l-0.16254 0.41301c-0.42899 1.0901-0.61228 2.2051-0.52999 3.2242 0.09379 1.1617 0.53512 2.1674 1.2763 2.9086l7.9515 7.9514 1.0487-1.0487 7.3512 8.2199c0.7312 0.7313 1.9899 0.7747 2.7646 0 0.7622-0.7623 0.7622-2.0025 0-2.7647z"
                />
                <path
                  d="m7.2303 13.539l-6.2276 6.2275c-0.76218 0.7622-0.76218 2.0024 0 2.7646 0.72406 0.7241 1.9766 0.788 2.7646 0l6.2276-6.2276-2.7647-2.7645z"
                />
                <path
                  d="m21.713 4.5851l-3.5326 3.5326-0.9216-0.92155 3.5326-3.5326-0.9216-0.92156-3.5325 3.5326-0.9216-0.92155 3.5326-3.5326-0.9215-0.92151-4.6078 4.6078c-0.5636 0.56369-0.9002 1.3122-0.9478 2.1076-0.0121 0.20154-0.0577 0.39977-0.1335 0.58832l2.9932 2.9932c0.1885-0.0758 0.3868-0.1215 0.5883-0.1335 0.7955-0.0475 1.5439-0.3841 2.1076-0.9478l4.6078-4.6078-0.9216-0.9216z"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <rect
                    transform="translate(.42432 .8833)"
                    width="22.233"
                    height="22.233"
                    fill="#fff"
                  />
                </clipPath>
              </defs>
            </svg>
          </span>

          <span class="menu"> {{traducciones.find((item)=>item.item == 'Menu').value}} </span>
        </li>
      </router-link>

      <router-link
        v-slot="{ isActive }"
        class="lista-deseos"
        :to="{ name: 'lista-deseos' }"
      >
        <li v-show="muestraDeseos"  :class="[isActive && 'lista-deseos active']">
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M21.3988 2.84889C20.3017 1.59038 18.8133 0.883301 17.2613 0.883301C15.7093 0.883301 14.2209 1.59038 13.1237 2.84889L11.9963 4.14154L10.8688 2.84889C8.5837 0.228975 4.87882 0.228975 2.59371 2.84889C0.308607 5.4688 0.308607 9.71652 2.59371 12.3364L3.72117 13.6291L11.9963 23.1166L20.2714 13.6291L21.3988 12.3364C22.4965 11.0785 23.1132 9.37205 23.1132 7.59266C23.1132 5.81327 22.4965 4.10681 21.3988 2.84889Z"
                fill="#283646"
              />
            </svg>
          </span>

          <span class="wishlist"> {{traducciones.find((item)=>item.item == 'Wishlist').value}} </span>
        </li>
      </router-link>

      <router-link
        v-slot="{ isActive }"
        class="destacados"
        :to="{ name: 'Sugerencias' }"
      >
        <li v-show="muestraSugerencias"  :class="[isActive && 'destacados active']">
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.1634 1.48834C11.3381 1.11788 11.6987 0.883301 12.0935 0.883301C12.4883 0.88325 12.8489 1.11783 13.0236 1.48829L15.6558 7.06904C15.8069 7.38934 16.0989 7.6114 16.4367 7.66278L22.3223 8.55778C22.713 8.61718 23.0376 8.9036 23.1596 9.29647C23.2816 9.6894 23.1799 10.1207 22.8971 10.4091L18.6382 14.7532C18.3937 15.0026 18.2822 15.3618 18.3399 15.7139L19.3451 21.8478C19.4118 22.255 19.2518 22.6665 18.9325 22.9093C18.6131 23.1522 18.1896 23.1842 17.8402 22.992L12.5762 20.0961C12.274 19.9299 11.9131 19.9299 11.6109 20.0961L6.34681 22.9919C5.99735 23.1842 5.57392 23.1521 5.25451 22.9093C4.9351 22.6664 4.77516 22.2549 4.8419 21.8477L5.84734 15.7139C5.90502 15.3618 5.79349 15.0026 5.54906 14.7532L1.29029 10.4091C1.00762 10.1207 0.90584 9.6894 1.02784 9.29642C1.14983 8.90349 1.47443 8.61713 1.86509 8.55773L7.75071 7.66267C8.08854 7.61129 8.38061 7.38924 8.53169 7.06889L11.1634 1.48834Z"
                fill="#283646"
              />
            </svg>
          </span>

          <span class="sugerencias"> {{traducciones.find((item)=>item.item == 'Sugerencias').value}} </span>
        </li>
      </router-link>

      <li v-show="muestraCesta" class="llamar cesta" @click="muestraCarrito">
        <div v-if="carrito.length > 0" class="notificacion-circulo">
          {{ carrito.length }}
        </div>
        <span>
          <svg
            width="24px"
            height="24px"
            viewBox="0 0 490 490"
            style="enable-background: new 0 0 490 490"
            xml:space="preserve"
            fill="#283646"
          >
            <g>
              <polygon
                points="409.1,116.7 367.1,116.7 367.1,87.5 409.1,87.5 409.1,0 262.5,0 262.5,87.5 304.9,87.5 304.9,116.7 79.3,116.7 
				79.3,206.5 409.1,206.5 			"
              />
              <path
                d="M414.9,225.9H75.1L4.3,384.6h481.4L414.9,225.9z M110.4,367.1H76.6c-5.4,0-9.7-4.3-9.7-9.7c0-5.4,4.3-9.7,9.7-9.7h33.8
				c5.4,0,9.7,4.3,9.7,9.7C120.2,362.8,115.5,367.1,110.4,367.1z M127.6,332.5H98c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h29.6
				c5.4,0,9.7,4.3,9.7,9.7S133,332.5,127.6,332.5z M140.8,297.9h-26.1c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h26.1
				c5.4,0,9.7,4.3,9.7,9.7S146.2,297.9,140.8,297.9z M151.7,262.9h-23.3c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h23.3
				c5.4,0,9.7,4.3,9.7,9.7S157.1,262.9,151.7,262.9z M211.6,367.1h-33.8c-5.4,0-9.7-4.3-9.7-9.7c0-5.4,4.3-9.7,9.7-9.7h33.8
				c5.4,0,9.7,4.3,9.7,9.7C221.3,362.8,216.6,367.1,211.6,367.1z M215.8,332.5h-29.6c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h29.6
				c5.4,0,9.7,4.3,9.7,9.7S220.9,332.5,215.8,332.5z M218.9,297.9h-26.1c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h26.1
				c5.4,0,9.7,4.3,9.7,9.7S224.4,297.9,218.9,297.9z M221.7,262.9h-23.3c-5.4,0-9.7-4.3-9.7-9.7s4.3-9.7,9.7-9.7h23.3
				c5.4,0,9.7,4.3,9.7,9.7S227.1,262.9,221.7,262.9z M268.3,243.4h23.3c5.4,0,9.7,4.3,9.7,9.7s-4.3,9.7-9.7,9.7h-23.3
				c-5.4,0-9.7-4.3-9.7-9.7S262.9,243.4,268.3,243.4z M261.3,288.2c0-5.4,4.3-9.7,9.7-9.7h26.1c5.4,0,9.7,4.3,9.7,9.7
				s-4.3,9.7-9.7,9.7H271C265.6,297.9,261.3,293.2,261.3,288.2z M264.4,322.8c0-5.4,4.3-9.7,9.7-9.7h29.6c5.4,0,9.7,4.3,9.7,9.7
				s-4.3,9.7-9.7,9.7h-29.6C269.1,332.5,264.4,328.2,264.4,322.8z M312.7,367.1h-33.8c-5.4,0-9.7-4.3-9.7-9.7c0-5.4,4.3-9.7,9.7-9.7
				h33.8c5.4,0,9.7,4.3,9.7,9.7C322.4,362.8,317.7,367.1,312.7,367.1z M328.6,253.2c0-5.4,4.3-9.7,9.7-9.7h23.3
				c5.4,0,9.7,4.3,9.7,9.7s-4.3,9.7-9.7,9.7h-23.3C332.9,262.9,328.6,258.6,328.6,253.2z M339.5,288.2c0-5.4,4.3-9.7,9.7-9.7h26.1
				c5.4,0,9.7,4.3,9.7,9.7s-4.3,9.7-9.7,9.7h-26.1C343.8,297.9,339.5,293.2,339.5,288.2z M352.7,322.8c0-5.4,4.3-9.7,9.7-9.7H392
				c5.4,0,9.7,4.3,9.7,9.7s-4.3,9.7-9.7,9.7h-29.6C357,332.5,352.7,328.2,352.7,322.8z M413.8,367.1H380c-5.4,0-9.7-4.3-9.7-9.7
				c0-5.4,4.3-9.7,9.7-9.7h33.8c5.4,0,9.7,4.3,9.7,9.7C423.5,362.8,418.8,367.1,413.8,367.1z"
              />
              <path
                d="M0,404.1V490h490v-85.9H0z M245,465.5c-12.8,0-23.3-10.5-23.3-23.3s10.5-23.3,23.3-23.3c12.8,0,23.3,10.5,23.3,23.3
				S257.8,465.5,245,465.5z"
              />
            </g>
          </svg>
        </span>
        <span class="pagar"> {{traducciones.find((item)=>item.item == 'Pagar').value}} </span>
      </li>
      <li v-show="muestraCamarero"  class="llamar llamar-modal" @click="OnLlamarCamarero">
        <span>
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M16.3878 10.5566C17.2345 9.53363 17.7441 8.2216 17.7441 6.79268C17.7441 3.20794 14.7478 0.883301 11.8383 0.883301C8.73206 0.883301 5.93262 3.34916 5.93262 6.79268C5.93262 8.27002 6.44216 9.53363 7.28894 10.5566C3.58509 11.062 0.72168 14.2474 0.72168 18.09V18.762L0.93802 18.9562C3.7742 21.5027 7.39185 22.966 11.187 23.1166V17.221H12.4897V23.1166C16.2848 22.966 19.9025 21.5027 22.7387 18.9563L22.955 18.762V18.0901C22.955 14.2474 20.0916 11.062 16.3878 10.5566ZM5.10755 20.2356C4.00696 19.6835 2.97072 18.9941 2.02441 18.1766V18.09C2.02441 15.7898 3.26297 13.7739 5.10755 12.6739V20.2356ZM14.6609 16.8624L11.8383 15.7891L9.01576 16.8624V13.2345L11.8383 14.3077L14.6609 13.2345V16.8624ZM11.8383 11.3985C9.47367 11.3985 7.52009 9.60498 7.2641 7.30609C8.11713 7.7643 9.07646 8.00934 10.0579 8.00934C11.4966 8.00934 12.8828 7.48565 13.961 6.53469C14.4129 6.13614 14.7972 5.67463 15.1049 5.16856C15.4142 5.87907 15.8628 6.52818 16.4325 7.07472C16.2863 9.48352 14.2818 11.3985 11.8383 11.3985ZM21.6523 18.1766C20.706 18.9941 19.6697 19.6835 18.5691 20.2356V12.674C20.4137 13.7739 21.6523 15.7898 21.6523 18.0901V18.1766Z"
                fill="#283646"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="22.2333"
                  height="22.2333"
                  fill="white"
                  transform="translate(0.72168 0.883301)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>

        <span class="camarero"> {{traducciones.find((item)=>item.item == 'Camarero').value}} </span>
      </li>
      <li v-show="muestraResena"  class="resena" @click="OnResena()">
        <span>
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0)">
              <path
                d="M0.0532227 3.84785V16.0762C0.0532227 17.2812 1.07151 18.2995 2.27656 18.2995H5.241V22.0051C5.241 22.1581 5.33512 22.2956 5.47779 22.3508C5.52151 22.3678 5.56672 22.3756 5.61156 22.3756C5.71383 22.3756 5.81388 22.3334 5.88577 22.2545L9.4809 18.2995H20.0632C21.2683 18.2995 22.2866 17.2812 22.2866 16.0762V3.84785C22.2866 2.6428 21.2683 1.62451 20.0632 1.62451H2.27656C1.07151 1.62451 0.0532227 2.6428 0.0532227 3.84785ZM14.8754 9.77673C14.8754 8.95929 15.5402 8.29451 16.3577 8.29451C17.1751 8.29451 17.8399 8.95929 17.8399 9.77673C17.8399 10.5942 17.1751 11.259 16.3577 11.259C15.5402 11.259 14.8754 10.5942 14.8754 9.77673ZM9.68767 9.77673C9.68767 8.95929 10.3524 8.29451 11.1699 8.29451C11.9873 8.29451 12.6521 8.95929 12.6521 9.77673C12.6521 10.5942 11.9873 11.259 11.1699 11.259C10.3524 11.259 9.68767 10.5942 9.68767 9.77673ZM4.49989 9.77673C4.49989 8.95929 5.16467 8.29451 5.98211 8.29451C6.79956 8.29451 7.46433 8.95929 7.46433 9.77673C7.46433 10.5942 6.79956 11.259 5.98211 11.259C5.16467 11.259 4.49989 10.5942 4.49989 9.77673Z"
                fill="#283646"
              />
            </g>
            <defs>
              <clipPath id="clip0">
                <rect
                  width="22.2333"
                  height="22.2333"
                  fill="white"
                  transform="translate(0.0532227 0.883301)"
                />
              </clipPath>
            </defs>
          </svg>
        </span>

        <span class="review"> {{traducciones.find((item)=>item.item == 'Review').value}} </span>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  name: "Footer",

  setup() {
    const store = useStore();

    const carrito = computed(() => store.state.carrito.carrito);
    console.log(store.state.restaurante.restaurante.modulos);
if (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Llamador') !== undefined)
            var muestraCamarero= true;
    if (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'WishList') !== undefined)
        var muestraDeseos = true;
    
    if (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Opiniones Clientes') !== undefined)
        var muestraResena = true;
    //if (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Destacados') !== undefined)
        var muestraSugerencias = true;
  
    if (store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Pedido') !== undefined
        || store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'AutoPedidos') !== undefined || store.state.restaurante.restaurante.modulos.find((element) => element.nombre === 'Comandero') !== undefined
        )
        var muestraCesta = true;
    
    
    const muestraNotificaciones = computed(() => store.state.menu.mostrarNotificaciones);
    const muestraPromociones = computed(() => store.state.menu.mostrarPromociones);
    const OnLlamarCamarero = () => {
      store.commit("menu/mostrarCamarero", true);
    };

    const OnResena = () => {
      store.commit("menu/mostrarResena", true);
    };

    const muestraCarrito = () => {
      store.commit("carrito/mostrarPanel");
    };
    const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale)
    return { OnLlamarCamarero, OnResena, muestraCarrito, muestraDeseos, muestraSugerencias, muestraResena, muestraCamarero, muestraCesta, carrito,traducciones };
  },
};
</script>

<style lang="scss" scoped>
.cesta {
  position: relative;

  svg {
    margin-bottom: 5px;
  }
}

.notificacion-circulo {
  position: absolute;
  background: red;
  color: #fff;
  right: -9px;
  top: 0px;
  padding: 2px 6px;
  border-radius: 100%;
}
</style>
